<template>
  <div>
    <img
      v-if="isPlain"
      :src="fullUrl"
      class="message__block--sticker"/>
    <tgs-player
      v-if="isAnimated"
      autoplay
      loop
      mode="normal"
      :src="fullUrl"
      class="message__block--sticker"/>
  </div>
</template>

<script>
import { getToken } from '@/vue-apollo';

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      token: getToken(),
    };
  },
  computed: {
    tokenQuery() {
      return `?token=${this.token}`;
    },
    multimedia() {
      return this.message.multimedia ?? {};
    },
    fullUrl() {
      return this.multimedia.file ? `${this.multimedia.file}${this.tokenQuery}` : '';
    },
    isPlain() {
      return this.multimedia.ext === 'webp';
    },
    isAnimated() {
      return this.multimedia.ext === 'tgs';
    },
  },
};
</script>
